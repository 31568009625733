import React from 'react';
import { Button, Form } from 'reactstrap';
import PropTypes from 'prop-types';

import TextInput from '../components/Custom/Forms/TextInput';
import PageSpinner from '../components/PageSpinner';

import { StringIsEmpty } from '../utils/common';

import { Link } from 'react-router-dom';
import withTranslation from '../hocs/withTranslation';

class AuthForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      missingUsername: false,
      missingPassword: false,
    };
  }

  handleSubmit = event => {
    event.preventDefault();
    const { username, password } = this.state;

    let emptyUsername = StringIsEmpty(username);
    let emptyPassword = StringIsEmpty(password);

    if (!emptyUsername)
      emptyUsername = StringIsEmpty(username.replace(' ', ''));
    if (!emptyPassword)
      emptyPassword = StringIsEmpty(password.replace(' ', ''));

    if (emptyUsername || emptyPassword) {
      this.setState({
        missingUsername: emptyUsername,
        missingPassword: emptyPassword,
        username: '',
        password: '',
      });
    } else {
      this.props.login(username, password);
    }
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      missingUsername: false,
      missingPassword: false,
    });
  };

  render() {
    const { children, loginFailed, showSpinner } = this.props;

    const { missingUsername, missingPassword } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <TextInput
          combinedStyle
          for={this.props.t(14)}
          placeholder={this.props.t(14)}
          name='username'
          onChange={this.onChange}
          error={missingUsername}
          errorText={this.props.t(32)}
          value={this.state.username}
          disabled={showSpinner}
        />

        <TextInput
          combinedStyle
          for={this.props.t(15)}
          placeholder={this.props.t(15)}
          name='password'
          type='password'
          onChange={this.onChange}
          error={missingPassword}
          errorText={this.props.t(33)}
          value={this.state.password}
          disabled={showSpinner}
        />

        {loginFailed ? (
          <p style={{ color: 'red' }}>{this.props.errorText}</p>
        ) : null}

        <small className='d-block text-right mb-4'>
          <Link to='/forgotPassword'>{this.props.t(164)}</Link>
        </small>

        {showSpinner ? <PageSpinner /> : null}
        {!showSpinner ? (
          <Button size='lg' block color='orange' type='submit'>
            {this.props.t(8)}
          </Button>
        ) : null}

        {children}
      </Form>
    );
  }
}

AuthForm.propTypes = {
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  passwordLabel: PropTypes.string,
};

AuthForm.defaultProps = {
  showLogo: true,
};

export default withTranslation(AuthForm);

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// Reducers
import auth from './auth';
import common from './common';
import customers from './customers';
import messages from './messages';
import settings from './settings';
import shop from './shop';
import invoice from './invoice';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    common,
    customers,
    messages,
    settings,
    shop,
    invoice,
  });

export default rootReducer;

import { withRouter } from 'react-router-dom';
import SourceLink from 'components/SourceLink';
import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  // Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import { connect } from 'react-redux';
import { logout } from './../../redux/actions/auth';
import { routes } from './../../utils/contentByAccessLevel';
import { getColor } from '../../utils/colors';
import Smspilot from '../Custom/Smspilot';
import withTranslation from '../../hocs/withTranslation';


const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    backgroundColor: null,
    logo: null,

    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
    navItems: [],
  };

  componentDidMount() {
    this.setState({
      navItems: routes(this.props.auth).filter(x => !x.hidden),
    });
  }

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  renderNavItem = ({ to, name, exact, Icon, active }, index) => {
    const onNavClick = () => {
      let updated = this.state.navItems.map(item => {
        item.active = false;
        return item;
      });
      updated[index].active = true;

      this.setState({
        navItems: updated,
      });
    };

    return (
      <NavItem key={index} className={bem.e('nav-item')}>
        <BSNavLink
          id={`navItem-${name}-${index}`}
          className=''
          tag={NavLink}
          to={to}
          exact={exact}
          active={active}
          activeClassName='active'
          onClick={onNavClick}
        >
          {Icon && <Icon className={bem.e('nav-item-icon')} />}
          <span className=''>{this.props.t(name)}</span>
        </BSNavLink>
      </NavItem>
    );
  };

  render() {
    const { backgroundColor, navItems } = this.state;

    let bgColor = backgroundColor || getColor('bgPrimary');

    const regNavItems = navItems.filter(ni => !ni.isBottom);
    const specialNavItems = navItems.filter(ni => ni.isBottom);
    return (
      <aside className={bem.b()} style={{ background: bgColor }}>
        <div className={bem.e('background')} />

        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink
              source='/'
              className='navbar-brand d-flex sidebar-navbar-brand'
            >
              <Smspilot />
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {regNavItems.map(this.renderNavItem)}
            <div className='cr-sidebar__divider' />
            {specialNavItems.map(this.renderNavItem)}
          </Nav>
          <span className='ml-4' style={{ fontWeight: 'initial', fontSize: 'small', color: 'black' }}>{process.env.REACT_APP_VERSION}</span>
        </div>
      </aside>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default withTranslation(
  withRouter(
    connect(mapStateToProps, {
      logout,
    })(Sidebar),
  ),
);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';

// Utils
import withTranslation from '../hocs/withTranslation';

// Actions
import { forgotPassword } from '../redux/actions/auth';

import TextInput from '../components/Custom/Forms/TextInput';
import Typography from '../components/Typography';
import Smspilot from '../components/Custom/Smspilot';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      success: false,
      loading: false,

      errObj: {
        emailError: '',
      },
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({ loading: true }, () => {
      this.props
        .forgotPassword({ mail: this.state.email })
        .then(res => {
          this.setState({
            success: true,
            loading: false,
          });
        })
        .catch(err => {
          this.setState({
            loading: false,
            errObj: {
              emailError: this.props.t(170),
            },
          });
        });
    });
  }

  render() {
    return (
      <div className='login_wrapper'>
        <div className='col-7 max-width'>
          {this.state.success && (
            <div>
              <Smspilot />

              <Typography type='h4' className='text-center'>
                {this.props.t(161).replace('[email]', this.state.email)}
              </Typography>

              <small className='d-block text-center mt-4'>
                <Link to='/login'>
                  <u>{this.props.t(8)}</u>
                </Link>
              </small>
            </div>
          )}

          {!this.state.success && (
            <Form onSubmit={this.onSubmit}>
              <Smspilot />

              <Typography type='h5' className='text-center mb-5'>
                {this.props.t(164)}
              </Typography>

              <TextInput
                for={this.props.t(16)}
                name='email'
                type='email'
                value={this.state.email}
                placeholder={this.props.t(16)}
                onChange={this.onChange}
                error={this.state.errObj.emailError}
                errorText={this.state.errObj.emailError}
                combinedStyle
              />

              <Button size='lg' block color='orange' type='submit'>
                {this.state.loading && <Spinner />}
                {!this.state.loading && this.props.t(166)}
              </Button>

              <small className='d-block text-center mt-4'>
                <Link to='/login'>
                  <u>{this.props.t(8)}</u>
                </Link>
              </small>
            </Form>
          )}
        </div>
        <div className='footer'>
          <div className='items'>
            <small className='link-item'>
              {`smspilot ${this.props.t(193).toLowerCase()} `}
              <a href='https://itpilot.dk'>itpilot.dk</a>
            </small>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(connect(null, {
  forgotPassword,
})(ForgotPassword));

import React from 'react';
import { Row, Col } from 'reactstrap';
import { Content } from 'components/Layout';
import { connect } from 'react-redux';
import ReactFlagsSelect from 'react-flags-select';

// Assets
import SplashImage from '../../assets/img/loginSplash.svg';

// Actions
import { setLanguage } from '../../redux/actions/common';
import { languages } from '../../utils/translation';
import withTranslation from '../../hocs/withTranslation';

// Components
import Typography from '../Typography';
import { getCurrentLang } from '../../utils/common';

const CustomEmptyLayout = ({
  children,
  titleCard,
  setLanguage,
  t,
  ...restProps
}) => {
  const renderFooterContent = () => {
    let labels = {};

    languages.forEach(item => {
      labels[item.lang.toUpperCase()] = item.name;
    });

    const onLanguageSelect = countryCode => {
      setLanguage(countryCode.toLowerCase(), false);
    };

    return (
      <div className='footer_wrapper'>
        <div className='footer'>
          <small>
            {`${t(194)} `} <a href='https://www.smspilot.com'>smspilot.com</a>
          </small>
          <ReactFlagsSelect
            defaultCountry={getCurrentLang()}
            countries={['DK', 'US']}
            customLabels={labels}
            onSelect={onLanguageSelect}
          />
        </div>
      </div>
    );
  };

  return (
    <main
      className='cr-app bg-light empty'
      {...restProps}
      style={{ overflowX: 'hidden' }}
    >
      <Content fluid>
        <Row style={{ height: '100vh' }}>
          <Col md={7}>
            <div className='splashImage'>
              <Typography type='h1' className='splashText'>
                {t(184)}
              </Typography>
              <img src={SplashImage} alt='splash' />
              {renderFooterContent()}
            </div>
          </Col>
          <Col className='empty__content'>{children}</Col>
        </Row>
      </Content>
    </main>
  );
};

function mapStateToProps(state) {
  return {};
}

export default withTranslation(
  connect(mapStateToProps, { setLanguage })(CustomEmptyLayout),
);

import { SET_GATEWAY_SETTINGS, SET_POINT_SETTINGS, SET_ADDON_POINT_SETTINGS } from '../constants';

const initialState = {
  gatewaySettings: [],
  gateways: [],
  pointSettings: [],
  addonPointSettings: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_GATEWAY_SETTINGS:
      return {
        ...state,
        gatewaySettings: action.payload.gatewaySettings,
        gateways: action.payload.gateways,
      };
    case SET_POINT_SETTINGS:
      return {
        ...state,
        pointSettings: action.payload,
      };
    case SET_ADDON_POINT_SETTINGS:
      return {
        ...state,
        addonPointSettings: action.payload,
      };
    default:
      return state;
  }
};

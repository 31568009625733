import { store } from '../../redux/store';

export const languages = [
  { name: 'Dansk', lang: 'dk' },
  { name: 'English', lang: 'us' },
];

export default function translate(textKey, translations = null) {
  let txtId = parseInt(textKey);
  if (isNaN(txtId)) return textKey;

  if (store && translations === null) {
    const state = store.getState().common;
    translations = state.translations;
  }

  let langId = window.localStorage.getItem('user_lang');
  if (!langId || langId === 'null') langId = 'dk';

  let langMessages = translations[langId];

  if (langMessages) return langMessages[txtId];
  return '   ';
}

export function translation(translations) {
  return textKey => translate(textKey, translations);
}

export function getAddonPointSettingTranslationKey(addonType) {
  let key;

  switch (addonType) {
    case 1:
    default:
      key = 142;
      break;
    case 2:
      key = 92;
      break;
  }

  return key;
}

import React, { Component } from 'react';
import { Input, Label, FormGroup, Col, FormText } from 'reactstrap';
import Icon from '../Icon';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';

/**
 * Custom text-input with ability to have a label displayed to its left.
 * @param {*} props - set properies here
 * @param {Boolean} row
 * @param {Boolean} disabled
 * @param {Number} labelSize
 * @param {Number} inputSize
 * @param {String} for
 * @param {String} type
 * @param {String} name
 * @param {*} value
 * @param {Function} onChange
 * @param {Function} onBlur
 * @param {String} placeholder
 * @param {Boolean} error - Bind to a boolean value
 * @param {String} errorText - Displayed text when error is true
 * @param {Boolean} combinedStyle - Label and input combined in one box
 */
class TextInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: props.type,
    };
  }

  render() {
    const {
      children,
      className,
      combinedStyle,
      disabled,
      error,
      errorText,
      extra,
      inputSize,
      labelSize,
      maxLength,
      name,
      onBlur,
      onChange,
      placeholder,
      row,
      rows,
      showHide,
      value,
      formText,
    } = this.props;

    // console.log(name, value);

    let lineRows = 3;
    if (value && (typeof value).toLowerCase() === 'string') {
      lineRows = value.split('\n').length;

      if (lineRows < 3) lineRows = 3;
      if (lineRows > 12) lineRows = 12;
    }

    const renderContent = (extraProps = {}) => {
      const onEyeClick = type => {
        this.setState({ type });
      };

      return (
        <div>
          <div className='input-group'>
            <Input
              type={this.state.type || 'text'}
              name={name}
              value={value || ''}
              onChange={onChange}
              onBlur={onBlur}
              placeholder={placeholder}
              disabled={disabled}
              bsSize={extraProps.bsSize}
              rows={rows || lineRows}
              maxLength={maxLength}
            >
              {children}
            </Input>
            {showHide && this.state.type === 'password' && (
              <Icon onClick={() => onEyeClick('text')} className='eye'>
                <IoMdEye />
              </Icon>
            )}
            {showHide && this.state.type === 'text' && (
              <Icon onClick={() => onEyeClick('password')} className='eye'>
                <IoMdEyeOff />
              </Icon>
            )}
          </div>
          {extra}
          {error && <div className='input-group__error'>{errorText}</div>}
        </div>
      );
    };

    let css = 'custom-text-input ' + className;
    if (!row && combinedStyle) {
      css += ' combined-style';
    }

    if (row) {
      return (
        <FormGroup className={css} row>
          {this.props.for && (
            <Label sm={labelSize} for={this.props.for}>
              {this.props.for}
              {formText && <FormText>{formText}</FormText>}
            </Label>
          )}
          <Col sm={inputSize}>{renderContent({ bsSize: 'md' })}</Col>
        </FormGroup>
      );
    }

    const result = (
      <FormGroup className={css}>
        {this.props.for && <Label for={this.props.for}>{this.props.for}</Label>}
        {renderContent()}
      </FormGroup>
    );

    if (formText) {
      return (
        <div className='combined-input-with-formtext'>
          {result}
          <FormText>{formText}</FormText>
        </div>
      );
    }
    return result;
  }
}

export default TextInput;

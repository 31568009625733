import React from 'react';
import itpilot_logo from '../../assets/img/smspilot-logo.svg';

const Smspilot = (props) => {
  return (
    <div className='smspilot_logo'>
      <img src={itpilot_logo} className='authform_logo' alt='itpilot' />
    </div>
  );
}

export default Smspilot;

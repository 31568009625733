// import withBadge from 'hocs/withBadge';
import React from 'react';
import { MdMenu, MdExitToApp } from 'react-icons/md';
import { Button, Nav, Navbar } from 'reactstrap';
import bn from 'utils/bemnames';
import { connect } from 'react-redux';
import { setLanguage } from './../../redux/actions/common';
import { NavLink } from 'react-router-dom';
import UserDisplay from '../Custom/UserDisplay';

const bem = bn.create('header');

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {
    const { user } = this.props.auth;
    let userArea = null;
    if (!this.props.auth.isAuthenticated) {
      return null;
    }

    if (user.userInfo) {
      userArea = <UserDisplay />;
    }
    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className='mr-2'>
          <Button onClick={this.handleSidebarControlButton} color='transparant'>
            <MdMenu size={25} />
          </Button>
        </Nav>
        <Nav className='ml-auto headerInfo'>
          {userArea}
          <NavLink to={'/logout'} activeClassName='active'>
            <MdExitToApp size={25} />
          </NavLink>
        </Nav>
      </Navbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { setLanguage })(Header);

import { SET_MESSAGES, SET_PAGES, SET_SEND_SMS_OPTIONS } from '../constants';

const initialState = {
  messages: [],
  pages: 1,
  sendSmsOptions: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };
    case SET_PAGES:
      return {
        ...state,
        pages: action.pages,
      };
    case SET_SEND_SMS_OPTIONS:
      return {
        ...state,
        sendSmsOptions: action.options
      };
    default:
      return state;
  }
};

import React, { Component } from 'react'

/**
 * Renders regular image or svg icons. 2 icon sources can be specified to overlay one on top of another.
 * Supports 4 render sizes as follows:
 * - l - Large  (48x48)
 * - m - Medium (32x32)
 * - s - Small  (24x24)
 * - t - Tiny   (16x16)
 *
 * Look at default props for reference.
 */
class Icon extends Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
    this.getSizeClass = this.getSizeClass.bind(this)
  }

  onClick(e) {
    // console.log('Icon onClick triggered!', e)
    if (this.props.onClick) {
      this.props.onClick(e)
    }
  }

  getSizeClass() {
    switch (this.props.size) {
      default:
      case undefined:
      case null:
      case 'l':
        return ' size-large'
      case 'm':
        return ' size-medium'
      case 's':
        return ' size-small'
      case 't':
        return ' size-tiny'   // (used for ex. help icon which can be 16x16)
    }
  }

  render() {
    let css = 'cr-icon' + this.getSizeClass()
    if (this.props.onClick) {
      css += ' can-click'
    }
    if (this.props.className) {
      css += ' ' + this.props.className
    }

    let ariaClass = ''
    if (this.props.ariaText !== null) {
      ariaClass = ' hint--' + this.props.ariaPos
    }

    return (
      <div className={css + ariaClass} aria-label={this.props.ariaText} onClick={this.onClick}>
        {this.props.children}
      </div>
    )
  }
}

Icon.defaultProps = {
  size: 's',
  className: '',
  ariaText: null,
  ariaPos: 'left',
  // onClick: null
}

export default Icon

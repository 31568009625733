export function getCountryTranslationKey(countryCode) {
  let key;

  switch (countryCode) {
    case '+30':
      key = 100;
      break;
    case '+31':
      key = 101;
      break;
    case '+32':
      key = 102;
      break;
    case '+33':
      key = 103;
      break;
    case '+34':
      key = 104;
      break;
    case '+36':
      key = 105;
      break;
    case '+39':
      key = 106;
      break;
    case '+40':
      key = 107;
      break;
    case '+41':
      key = 108;
      break;
    case '+43':
      key = 109;
      break;
    case '+44':
      key = 110;
      break;
    default:
    case '+45':
      key = 111;
      break;
    case '+46':
      key = 112;
      break;
    case '+47':
      key = 113;
      break;
    case '+48':
      key = 114;
      break;
    case '+49':
      key = 115;
      break;
    case '+298':
      key = 116;
      break;
    case '+350':
      key = 117;
      break;
    case '+351':
      key = 118;
      break;
    case '+352':
      key = 119;
      break;
    case '+353':
      key = 120;
      break;
    case '+354':
      key = 121;
      break;
    case '+356':
      key = 122;
      break;
    case '+357':
      key = 123;
      break;
    case '+358':
      key = 124;
      break;
    case '+359':
      key = 125;
      break;
    case '+370':
      key = 126;
      break;
    case '+371':
      key = 127;
      break;
    case '+372':
      key = 128;
      break;
    case '+376':
      key = 129;
      break;
    case '+377':
      key = 130;
      break;
    case '+378':
      key = 131;
      break;
    case '+379':
      key = 132;
      break;
    case '+385':
      key = 133;
      break;
    case '+386':
      key = 134;
      break;
    case '+420':
      key = 135;
      break;
    case '+421':
      key = 136;
      break;
    case '+423':
      key = 137;
      break;
  }

  return key;
}

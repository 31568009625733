import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Input, Button, Spinner } from 'reactstrap';

import {
  getAddonPointSettings,
  updateAddonPointSetting,
  resetAddonPointSetting,
} from '../redux/actions/settings';
import withTranslation from '../hocs/withTranslation';
import { getAddonPointSettingTranslationKey } from '../utils/translation';

import PageSpinner from '../components/PageSpinner';
import toast from '../utils/toast';

class PointAddonItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      customerId: props.customerId,
      setting: props.setting,
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.customerId !== this.state.customerId ||
      Object.entries(nextProps.setting).toString() !==
        Object.entries(this.props.setting).toString()
    ) {
      this.setState({
        customerId: nextProps.customerId,
        setting: nextProps.setting,
      });
    } else return null;
  }

  onUpdate = () => {
    let dataObj = {
      ...this.state.setting,
      customerId: this.state.customerId,
    };
    this.setState({ updating: true }, () => {
      this.props
        .updateAddonPointSetting(dataObj)
        .then(() => {
          toast.success(this.props.t(329));
          this.setState({ updating: false, errors: {} });
        })
        .catch(err => {
          let errors = {};
          if (err.response) {
            if (err.response.data) {
              errors[`${err.response.data.value}`] = this.props.t(
                err.response.data.dictionaryKey,
              );
            }
          }
          this.setState({
            updating: false,
            errors,
          });
        });
    });
  };

  render() {
    let { updating, setting, customerId, errors } = this.state;

    const onReset = () => {
      this.props.resetAddonPointSetting(customerId, setting.addonType);
    };

    const onChange = e => {
      this.setState({
        setting: {
          ...setting,
          points: e.target.value.replace(',', '.'),
        },
      });
    };

    let err;
    if (errors[setting.addonType]) {
      err = errors[setting.addonType];
    }

    return (
      <tr>
        <td colSpan={2}>
          {this.props.t(getAddonPointSettingTranslationKey(setting.addonType))}
        </td>
        <td>
          <Input
            name='addon'
            type='number'
            step='any'
            value={setting.points}
            onChange={onChange}
            bsSize='sm'
            style={{ textAlign: 'right' }}
          />
          {err && <div style={{ color: 'red', textAlign: 'right' }}>{err}</div>}
          {setting.isCustom && (
            <div style={{ textAlign: 'right' }}>{this.props.t(356)}</div>
          )}
        </td>
        <td>
          <div className='d-flex justify-content-around'>
            {setting.customerId != null && (
              <Button color='orange' className='mr-4' onClick={onReset}>
                {this.props.t(279)}
              </Button>
            )}
            <Button color='orange' className='' onClick={this.onUpdate}>
              {!updating && this.props.t(19)}
              {updating && <Spinner />}
            </Button>
          </div>
        </td>
      </tr>
    );
  }
}

class PointAddons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.props.getAddonPointSettings(this.props.customerId).then(() => {
        this.setState({ loading: false });
      });
    });
  }

  render() {
    let { addonPointSettings, customerId } = this.props;

    return (
      <div className='settings gateway_tables'>
        <Table size='sm' striped={this.props.striped}>
          <thead>
            <tr>
              <th colSpan={2}>{this.props.t(147)}</th>
              <th>{this.props.t(148)}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.loading && (
              <tr>
                <td colSpan={4}>
                  <PageSpinner />
                </td>
              </tr>
            )}
            {addonPointSettings.map((x, key) => (
              <PointAddonItem
                key={key}
                setting={x}
                customerId={customerId}
                {...this.props}
              />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    addonPointSettings: state.settings.addonPointSettings,
  };
}

export default withTranslation(
  connect(mapStateToProps, {
    getAddonPointSettings,
    updateAddonPointSetting,
    resetAddonPointSetting,
  })(PointAddons),
);

import {
  CustomEmptyLayout,
  LayoutRoute,
  MainLayout,
} from './components/Layout';
import PageSpinner from 'components/PageSpinner';
import React from 'react';
import { routes } from './utils/contentByAccessLevel';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

// Page Imports
import LoginPage from './pages/Login';
import LogoutPage from './pages/Logout';
import RegisterPage from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

// Redux
import { ConnectedRouter } from 'connected-react-router';
import { history } from './redux/store';
import translate from './utils/translation';
import withTranslation from './hocs/withTranslation';
import { connect } from 'react-redux';

const { detect } = require('detect-browser');

class App extends React.Component {
  renderUnsupportedBrowser() {
    return (
      <div className='unsupported-container'>
        <p>
          {translate(197)}
          <a href='https://www.microsoft.com/da-dk/edge'>Edge</a>,
          <a href='https://www.google.com/chrome/'>Chrome</a>,
          <a href='https://www.apple.com/safari/'>Safari</a>
          {` `}
          {translate(198).toLowerCase()}
          <a href='https://www.mozilla.org/da/firefox/new/'>Firefox</a>
        </p>
      </div>
    );
  }

  render() {
    const browser = detect();

    if (browser) {
      if (browser.name === 'ie') {
        return this.renderUnsupportedBrowser();
      }
    }

    const getRoutes = () => {
      const getRoute = (route, index) => {
        return (
          <Route
            key={index}
            exact={route.exact}
            path={route.to}
            component={props => <route.component {...props} />}
          />
        );
      };
      let _routes = routes(this.props.auth, false);
      let result = _routes.map(getRoute);

      if (_routes.length > 0) {
        _routes[0].to = '/';
        result.push(getRoute(_routes[0], _routes.length));
      }

      return result;
    };

    return (
      <ConnectedRouter history={history}>
        <BrowserRouter>
          <Switch>
            <LayoutRoute
              exact
              path='/login'
              layout={CustomEmptyLayout}
              component={props => <LoginPage {...props} />}
            />
            <LayoutRoute
              exact
              path='/logout'
              layout={CustomEmptyLayout}
              component={props => <LogoutPage {...props} />}
            />
            {<LayoutRoute
              exact
              path='/register'
              layout={CustomEmptyLayout}
              component={props => <RegisterPage {...props} />}
            />}
            <LayoutRoute
              exact
              path='/forgotPassword'
              layout={CustomEmptyLayout}
              component={props => <ForgotPassword {...props} />}
            />
            <LayoutRoute
              exact
              path='/resetPassword'
              layout={CustomEmptyLayout}
              component={props => <ResetPassword {...props} />}
            />
            <MainLayout breakpoint={this.props.breakpoint} history={history}>
              <React.Suspense fallback={<PageSpinner />}>
                {getRoutes()}
              </React.Suspense>
            </MainLayout>
            <Redirect to='/' />
          </Switch>
        </BrowserRouter>
      </ConnectedRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    // console.log('bp xs', width);
    return { breakpoint: 'xs' };
  }

  if (575 <= width && width < 767) {
    // console.log('bp sm', width);
    return { breakpoint: 'sm' };
  }

  if (767 <= width && width < 991) {
    // console.log('bp md', width);
    return { breakpoint: 'md' };
  }

  if (991 <= width && width < 1200) {
    // console.log('bp lg', width);
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    // console.log('bp xl', width);
    return { breakpoint: 'xl' };
  }

  // console.log('bp xs d', width);
  return { breakpoint: 'xs' };
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default withTranslation(
  connect(mapStateToProps)(componentQueries(query)(App)),
);

// API URL
export const API_URL = process.env.REACT_APP_API_URL;

// Auth constants
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_LOGIN_AS = 'SET_LOGIN_AS';
export const LOGOUT_AS = 'LOGOUT_AS';

// Customer constants
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const SET_CUSTOMER = 'SET_CUSTOMER';

// SMS/Message constans
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_PAGES = 'SET_PAGES';
export const SET_COUNTRY_CODES = 'SET_COUNTRY_CODES';
export const SET_SEND_SMS_OPTIONS = 'SET_SEND_SMS_OPTIONS';

// Common constants
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const RECAPTCHA_CLIENT_KEY = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY;

// Settings constants
export const SET_GATEWAY_SETTINGS = 'SET_GATEWAY_SETTINGS';
export const SET_POINT_SETTINGS = 'SET_POINT_SETTINGS';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SET_INVOICE_HISTORY_PAGES = 'SET_INVOICE_HISTORY_PAGES';
export const SET_INVOICE_HISTORY = 'SET_INVOICE_HISTORY';
export const SET_ADDON_POINT_SETTINGS = 'SET_ADDON_POINT_SETTINGS';

// Shop constants
export const SET_POINT_PACKAGES = 'SET_POINT_PACKAGES';

// User Types
export const USER_TYPE = {
  SuperAdmin: 1,
  ClientContact: 2,
};

// Invoice types
export const INVOICING_TYPES = {
  Prepaid: 1,
  Consumption: 2,
};

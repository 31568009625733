export const getUserData = auth => {
  if (auth.loginAsUser)
    return JSON.parse(auth.loginAsUser)
  else
    return auth.user
};

/**
 * Checks if a role has access
 * @param {Array<Number>} roles Permitted roles
 * @param {Number} role Role
 */
export const hasAccess = (roles = [], role) => {
  return roles.indexOf(role) !== -1;
}

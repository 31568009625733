import { SET_CURRENT_USER, SET_LOGIN_AS, LOGOUT_AS } from '../constants';

const initialState = {
  isAuthenticated: false,
  user: {},
  loginAsUser: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !(Object.keys(action.user).length === 0),
        user: action.user,
      };
    case SET_LOGIN_AS:
      return {
        ...state,
        loginAsUser: action.user,
      };
    case LOGOUT_AS:
      return {
        ...state,
        loginAsUser: null,
      };
    default:
      return state;
  }
};
